<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">Ordenes</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ totalRows }} en total
          </span>
                </h3>
                <div class="card-toolbar">
                    <a
                            @click="goNewItem"
                            class="btn btn-primary font-weight-bolder font-size-sm mr-3"
                    >
                        Nueva orden
                    </a>
                </div>
            </div>
            <div class="card-body pb-10" data-app>
                <div class="row">
                    <div class="col-md-4">
                        <b-form-group
                                id="form-company-group"
                                label="Empresa"
                                label-for="input-2"
                        >
                            <b-form-select
                                    id="form-enterprise-field"
                                    v-model="filters.enterpriseId"
                                    :options="enterprises"
                                    required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                                id="form-training-group"
                                label="Training"
                                label-for="input-1"
                        >
                            <b-form-select
                                    id="form-training-field"
                                    v-model="filters.trainingId"
                                    :options="trainings"
                                    required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <b-form-group
                                id="form-start_date-group"
                                label="Fecha de inicio"
                                label-for="input-3"
                        >
                            <b-form-datepicker
                                    id="form-start_date-field"
                                    v-model="filters.start_date"
                                    v-bind="labels[locale] || {}"
                                    :locale="locale"
                                    class="mb-2"
                            >
                            </b-form-datepicker>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                                id="form-end_date-group"
                                label="Fecha de finalización"
                                label-for="input-4"
                        >
                            <b-form-datepicker
                                    id="form-end_date-field"
                                    v-model="filters.end_date"
                                    v-bind="labels[locale] || {}"
                                    :locale="locale"
                                    class="mb-2"
                            >
                            </b-form-datepicker>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <b-button type="button" variant="primary" @click="findOrders()">Buscar</b-button>
                        <b-button class="ml-2" type="reset" variant="danger" @click="cleanFilters()">Resetear</b-button>
                        <div style="width: 1px; height: 30px; border-right: 1px #b8c2cc solid;
                                        display: inline-flex; margin: 0px 2px 0px 10px; position: relative; top: 15px;"></div>
                        <b-button class="ml-2" type="button" variant="primary" @click="orderOxportExcel()">Exportar</b-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <v-text-field
                                v-model="dtSearch"
                                label="Buscar"
                                append-icon="search"
                                class="mx-4"
                                single-line
                                hide-details
                        ></v-text-field>
                        <v-data-table
                                :headers="headers"
                                :items="list"
                                :options.sync="options"
                                :server-items-length="totalRows"
                                :loading="isLoadingTable"
                                :footer-props="{
                          itemsPerPageOptions: [5, 10, 25, 50],
                          showFirstLastPage: true,
                          itemsPerPageText: 'Resultados por página'
                      }"
                                :items-per-page="itemsPerPage"
                                class="elevation-1"
                                locale="es"
                        >
                            <template v-slot:item.start_date="{ item }">
                                {{ item.start_date | formatDate}}
                            </template>
                            <template v-slot:item.end_date="{ item }">
                                {{ item.end_date | formatDate}}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="" align="left">
                                    <a @click="goDetail(item)" class="btn btn-icon btn-light btn-sm"
                                       title="Ver detalles">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                      src="media/svg/icons/Design/ZoomMinus.svg"
                                      aria-label="Ver detalles"
                              ></inline-svg>
                            </span>
                                    </a>
                                    <a @click="editItem(item)" class="btn btn-icon btn-light btn-sm mx-3"
                                       v-if="item.canModify"
                                       title="Modificar">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                      src="media/svg/icons/Communication/Write.svg"
                                      aria-label="Modificar"
                              ></inline-svg>
                            </span>
                                    </a>
                                    <a
                                            @click="cancelConfirmation(item.id)"
                                            v-b-modal.modal-cancel
                                            class="btn btn-icon btn-light btn-sm"
                                            v-if="item.canCancel"
                                            title="Cancelar"
                                    >
                                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                          src="media/svg/downloaded/red-cross-icon.svg"
                                                          aria-label="Cancelar"
                                                  ></inline-svg>
                                                </span>
                                    </a>
                                    <a
                                            @click="deleteConfirmation(item.id)"
                                            v-b-modal.modal-delete
                                            class="btn btn-icon btn-light btn-sm"
                                            v-if="item.canDelete"
                                            title="Eliminar"
                                    >
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg
                                      src="media/svg/icons/General/Trash.svg"
                                      aria-label="Eliminar"
                              ></inline-svg>
                            </span>
                                    </a>
                                </div>
                            </template>
                            <template slot="no-data"> Ningún dato para mostrar</template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-delete" title="¿Estas seguro?">
            Los datos serán borrados de forma permanente
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="deleteItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
        <b-modal id="modal-cancel" title="¿Estas seguro?">
            La orden sera cancelada
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="cancelItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {
        ORDERS_GET_ORDERS_LIST,
        ORDERS_SET_SELECTED_ORDER_ID,
        ORDERS_SET_NEW_ORDER_DATA,
        ORDERS_UPDATE_ORDER,
        ORDERS_DELETE_ORDER,
        ORDERS_GET_TRAININGS_LIST,
        ORDERS_GET_ENTERPRISE_LIST,
        ORDERS_GET_ENTERPRISE_LIST_EXT,
        ORDERS_EXPORT_ORDERS_LIST,
    } from "@/store/modules/orders.module";

    export default {
        name: "OrdersList",
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 10,
                options: {},
                isLoading: false,
                isLoadingTable: false,
                headers: [
                    {
                        text: "Training",
                        value: "training.name",
                        align: "start",
                        sortable: false,
                    },
                    {
                        text: "Empresa",
                        value: "enterprise.razon_social_empresa",
                        sortable: false
                    },
                    {
                        text: "Fecha inicio",
                        value: "start_date",
                        align: "start",
                        sortable: false,
                    },
                    {
                        text: "Fecha cierre",
                        value: "end_date",
                        align: "start",
                        sortable: false,
                    },
                    {
                        text: "Estado",
                        value: "status",
                        align: "start",
                        sortable: false,
                    },

                    {text: "Acciones", value: "actions", align: "center", sortable: false},
                ],
                filtersData: {
                    enterprise_id: "",
                    training_id: "0",
                    start_date: "",
                    end_date: "",
                    itemsPerPage: 50,
                    page: 1,
                },
                dtSearch: "",
                idToDelete: null,
                idToCancel: null,
                filters: {},
                locale: "es-ES",
                locales: [{value: "es-ES", text: "Español ES (es-ES)"}],
                labels: {
                    "es-ES": {
                        labelPrevDecade: "Década anterior",
                        labelPrevYear: "Año anterior",
                        labelPrevMonth: "El mes pasado",
                        labelCurrentMonth: "Mes actual",
                        labelNextMonth: "Próximo mes",
                        labelNextYear: "El próximo año",
                        labelNextDecade: "La próxima década",
                        labelToday: "hoy dia",
                        labelSelected: "Fecha seleccionada",
                        labelNoDateSelected: "Sin fecha elegida",
                        labelCalendar: "Calendario",
                        labelNav: "Navegación de calendario",
                        labelHelp:
                            "Utilice las teclas de flecha para navegar por el calendario",
                    },
                },
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Ordenes", route: "#"},
                {title: "Listado de ordenes"},
            ]);
            this.$store.commit(ORDERS_SET_NEW_ORDER_DATA, {
                enterprise_id: null,
                training_id: null,
                start_date: null,
                end_date: null,
                minimal_choice: 1,
                branch: null,
                area: null,
                type: 1,
                state: 1,
                welcome_message: null,
                bye_message: null,
                original_file: null,
                file: [],
                students: [],
                send_welcome_card: 1,
            });
            this.$store.dispatch(ORDERS_GET_TRAININGS_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(ORDERS_GET_ENTERPRISE_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(ORDERS_GET_ENTERPRISE_LIST_EXT, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
        },
        methods: {
            goNewItem() {
                this.$router.push("new-order");
            },
            goDetail(item) {
                this.$store.commit(ORDERS_SET_SELECTED_ORDER_ID, item.id);
                this.$router.push("order-detail");
            },
            deleteConfirmation(id) {
                this.idToDelete = id;
            },
            cancelConfirmation(id) {
                this.idToCancel = id;
            },
            editItem(item) {
                this.$store.commit(ORDERS_SET_SELECTED_ORDER_ID, item.id);
                this.$router.push("order-modify");
            },
            deleteItem() {
                this.$store.dispatch(ORDERS_DELETE_ORDER, {
                    id: this.idToDelete
                }).then(() => {
                    window.location.reload()
                })
            },
            cancelItem() {
                this.$store.dispatch(ORDERS_UPDATE_ORDER, {
                    id: this.idToCancel,
                    setCancelStatus: true
                }).then(() => {
                    window.location.reload()
                })
            },
            getOrderList(dataPagination) {
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(ORDERS_GET_ORDERS_LIST, {
                    page: dataPagination.page,
                    itemsPerPage: dataPagination.itemsPerPage,
                    search: this.dtSearch,
                    filters: this.filters,
                }).then(() => {
                    scope.isLoading = false
                })
            },
            findOrders(){
                this.getOrderList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            },
            cleanFilters(){
                this.filters = {}
            },
            orderOxportExcel(){
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(ORDERS_EXPORT_ORDERS_LIST, {
                    filters: {
                        search: this.dtSearch,
                        ... scope.filters
                    }
                }).then(() => {
                    scope.isLoading = false
                })
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.orders.ordersListTotal;
            },
            list() {
                return this.$store.state.orders.ordersList.map((item) => {
                    return {
                        id: item.id,
                        training: item.training,
                        enterprise: item.enterprise,
                        start_date: item.start_date,
                        end_date: item.end_date,
                        status: item.status.name + item.statusSecondName,
                        welcome_message: item.welcome_message,
                        bye_message: item.bye_message,
                        students: item.students,
                        minimal_choice: item.minimal_choice,
                        canModify: item.canModify,
                        canDelete: item.canDelete,
                        canCancel: item.canCancel,
                        canOnlyExtended: item.canOnlyExtended,
                    };
                });
            },
            enterprises() {
                return this.$store.state.orders.orderEnterpriseList.map((item) => {
                    return {text: item.razon_social_empresa, value: item.id};
                });
            },
            trainings() {
                return this.$store.state.orders.orderTrainingList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
        },
        watch: {
            options: {
                handler(dataPagination) {
                    this.getOrderList(dataPagination)
                },
                deep: true,
            },
            dtSearch() {
                this.getOrderList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            }
        },
    };
</script>

<style>
    .v-data-footer__select .v-select {
        padding: 15px !important;
    }
</style>
